import { Injectable } from "@angular/core";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { APIConstant } from "../constant";
import { SchoolSubject } from 'models';
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AcademicSubjectsClassroomService extends CRUDService<SchoolSubject> {

    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.academicSubjectsByClassroom);
    }

    byClassroomChangeOrder(params: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.put(`${APIConstant.byClassroomChangeOrder}?${query}`, {});
    }

}
