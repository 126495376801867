import { Injectable } from "@angular/core";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { APIConstant } from "../constant";
import { SchoolSubject } from 'models';
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class SubjectSetupService extends CRUDService<SchoolSubject> {

    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.academicSubjects);
    }

    changeSubjectOrder(params: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.put(`${APIConstant.subjectChangeOrder}?${query}`, {});
    }

    getDefaultSubjects(boardName){
        const query = CommonUtility.convertToUrlParams(boardName);
        return this.baseService.get(`${APIConstant.defaultSubjects}?${query}`);
    }

    getAllSubjectsBySchoolCode()
    {
       
        return this.baseService.get(`${APIConstant.academicSubjects}`);
    }

}
